import base64 from 'base-64';
import { AxiosHeaders } from 'axios';

const BASIC_AUTH_LOGIN = process.env.NEXT_PUBLIC_BASIC_AUTH_LOGIN;
const BASIC_AUTH_PASSWORD = process.env.NEXT_PUBLIC_BASIC_AUTH_PASSWORD;

const baseHeaders = new Headers();
baseHeaders.set('Content-Type', 'application/json');
baseHeaders.set('Accept', 'application/json');

if (BASIC_AUTH_LOGIN && BASIC_AUTH_PASSWORD) {
  baseHeaders.set(
    'Authorization',
    `Basic ${base64.encode(`${BASIC_AUTH_LOGIN}:${BASIC_AUTH_PASSWORD}`)}`
  );
}

export const baseAxiosHeaders = new AxiosHeaders();

baseHeaders.forEach((value, key) => {
  baseAxiosHeaders.set(key, value, true);
});
