import { axiosInstance } from '@/shared/api/axios';
import { API } from '@/shared/api/api';
import { IGlobalOptions } from '@/shared/api/options/getGlobalOptions/types';

interface Params {
  lang: string;
}

export const getGlobalOptions = async (params?: Params) => {
  const response = await axiosInstance.get<IGlobalOptions>(
    API.getGlobalOptions,
    {
      params,
    }
  );

  return response.data;
};
