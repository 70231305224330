import { API } from '@/shared/api/api';
import { axiosInstance } from '@/shared/api/axios';

/* TODO: пробросить параметр lang */
export const refreshToken = async () => {
  try {
    const { data } = await axiosInstance.post(
      API.refreshToken,
      {},
      { withCredentials: true }
    );
    const newToken = data.data.token;
    localStorage.setItem('token', JSON.stringify(newToken));

    return newToken;
  } catch (error) {
    console.error('Error refreshing token:', error);
    return null;
  }
};
