import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { baseAxiosHeaders } from './prepareHeaders';
import { refreshToken } from '@/shared/api/auth/refreshToken';

const API_URL = process.env.NEXT_PUBLIC_FRONT_API_URL;
const PROXY_API_URL = process.env.NEXT_PUBLIC_FRONT_PROXY_API_URL;

interface CustomInternalAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

export type TResponse<D = unknown> = {
  success?: boolean;
  data?:
    | D
    | string
    | Array<Record<string, string>>
    | Record<string, Record<string, string>>;
  result?:
    | D
    | string
    | Array<Record<string, string>>
    | Record<string, Record<string, string>>;
  errors?:
    | string
    | Array<Record<string, string>>
    | Record<string, Record<string, string>>;
};

export const axiosInstance = axios.create({
  headers: baseAxiosHeaders,
  withCredentials: true,
  baseURL: typeof window === 'undefined' ? API_URL : PROXY_API_URL || API_URL,
});

const authRequestInterceptor = (config: InternalAxiosRequestConfig) => {
  const token =
    typeof window !== 'undefined'
      ? (JSON.parse(localStorage.getItem('token') ?? 'null') as string | null)
      : null;

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
};

const refreshTokenInterceptor = async (error: AxiosError<TResponse>) => {
  const originalRequest = error.config as CustomInternalAxiosRequestConfig;

  if (
    error.response?.status === 403 &&
    !originalRequest._retry &&
    /* TODO: что тут будет в англ. версии? */
    error.response?.data.data === 'Требуется обновить токен'
  ) {
    originalRequest._retry = true;

    const newToken = await refreshToken();

    if (newToken) {
      originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
      return axiosInstance(originalRequest);
    }
  }

  return Promise.reject(error);
};

const errorInterceptor = (response: AxiosResponse<TResponse<any>>) => {
  /* Если бэк выдает success = false и при этом статус 200-299 - выбрасываем ошибку */
  if (response.data.success === false) {
    const axiosError = new AxiosError(
      undefined,
      undefined,
      response.config,
      response.request,
      response
    );

    return Promise.reject(axiosError);
  }

  return response;
};

axiosInstance.interceptors.request.use(authRequestInterceptor);
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  refreshTokenInterceptor
);
axiosInstance.interceptors.response.use(errorInterceptor);
