import { QueryClient, useQuery } from '@tanstack/react-query';
import { getGlobalOptions } from '@/shared/api/options/getGlobalOptions';

type Params = Parameters<typeof getGlobalOptions>[0];

export const useGetGlobalOptions = (params: Params) => {
  return useQuery({
    queryKey: getUseGetGlobalOptionsQueryKey(params),
    queryFn: () => getGlobalOptions(params),
    staleTime: Infinity,
  });
};

export const prefetchGetGlobalOptions = async (
  queryClient: QueryClient,
  params: Params
) => {
  await queryClient.prefetchQuery({
    queryKey: getUseGetGlobalOptionsQueryKey(params),
    queryFn: () => getGlobalOptions(params),
  });
};

export const getUseGetGlobalOptionsQueryKey = (params: Params) => [
  'getGlobalOptions',
  params,
];
