import { cva } from 'class-variance-authority';

export const cvaRoot = cva(['Heading-cvaRoot', 'font-extrabold'], {
  variants: {
    size: {
      h1: ['text-4.8 md-max:text-3.4'],
      h2: ['text-4 md-max:text-3.2'],
      h3: ['text-3.2 md-max:text-2.8-800'],
      'subtitle-lg': ['text-2.4 font-bold md-max:text-1.8'],
      'subtitle-sm': ['text-2 !font-semibold md-max:text-1.8'],
      h4: ['text-2.8-800 md-max:text-2.6'],
    },
    center: {
      true: ['text-center'],
    },
    textColor: {
      primary: ['text-cPurple700'],
    },
  },
  defaultVariants: {
    size: 'h1',
  },
});
