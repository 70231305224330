'use client';
import type { VariantProps } from 'class-variance-authority';
import clsx from 'clsx';
import { FC, ReactNode, MouseEvent } from 'react';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import {
  cvaButton,
  cvaButtonInner,
  cvaButtonInnerRow,
  cvaIcon,
} from './ButtonStyles';
import { LocalizedLink } from '@/shared/i18n/routing';

export interface IButtonProps
  extends VariantProps<typeof cvaButton>,
    VariantProps<typeof cvaButtonInnerRow> {
  id?: string;
  href?: string;
  children: ReactNode;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  icon?: ReactNode;
  disabled?: boolean;
  type?: 'button' | 'submit';
  onlyIcon?: boolean;
  className?: string;
  innerClassName?: string;
  innerRowClassName?: string;
  innerRef?: any;
  target?: string;
  isLoading?: boolean;
  hoverTextAnimation?: boolean;
  customIcon?: () => ReactNode;
}

const Button: FC<IButtonProps> = ({
  id,
  href = '',
  innerRef,
  children,
  onClick,
  icon,
  disabled,
  isIconAfter,
  size,
  type,
  onlyIcon = false,
  className,
  innerClassName,
  innerRowClassName,
  stretched,
  isLoading,
  target,
  customIcon,
  theme,
}) => {
  return href ? (
    <LocalizedLink
      id={id}
      target={target}
      rel={'noreferrer nofollower'}
      scroll={false}
      ref={innerRef}
      href={href}
      onClick={onClick}
      className={clsx(
        cvaButton({
          size,
          stretched,
          isLoading,
          isIconAfter: icon ? Boolean(isIconAfter) : 'default',
          theme,
        }),
        className
      )}>
      {onlyIcon ? (
        <>
          {icon ? <div className={cvaIcon()}>{icon}</div> : null}{' '}
          {customIcon ? customIcon() : null} {children}
        </>
      ) : (
        <>
          {icon || customIcon ? (
            <>
              {icon ? (
                <div className={cvaIcon()}>{icon}</div>
              ) : customIcon ? (
                customIcon()
              ) : null}
            </>
          ) : null}
          <span className={clsx(cvaButtonInner(), innerClassName)}>
            <span className={clsx(cvaButtonInnerRow(), innerRowClassName)}>
              {customIcon ? customIcon() : null} {children}
            </span>
            {isLoading ? <LoadingSpinner size={'sm'} color={'black'} /> : null}
          </span>
        </>
      )}
    </LocalizedLink>
  ) : (
    <button
      ref={innerRef}
      id={id}
      disabled={disabled}
      type={type ?? 'button'}
      onClick={onClick}
      className={clsx(
        cvaButton({
          isLoading,
          stretched,
          isIconAfter: icon ? Boolean(isIconAfter) : 'default',
          size,
          theme,
        }),
        className
      )}>
      {onlyIcon ? (
        <>
          {icon ? <div className={cvaIcon()}>{icon}</div> : null}{' '}
          {customIcon ? customIcon() : null} {children}
        </>
      ) : (
        <>
          {icon || customIcon ? (
            <>
              {icon ? (
                <div className={cvaIcon()}>{icon}</div>
              ) : customIcon ? (
                customIcon()
              ) : null}
            </>
          ) : null}
          <span className={clsx(cvaButtonInner(), innerClassName)}>
            <span className={clsx(cvaButtonInnerRow(), innerRowClassName)}>
              {customIcon ? customIcon() : null} {children}
            </span>
            {isLoading ? <LoadingSpinner size={'sm'} color={'black'} /> : null}
          </span>
        </>
      )}
    </button>
  );
};

export default Button;
