import { cva } from 'class-variance-authority';

export const cvaButton = cva(
  [
    'ButtonStyles-cvaButton',
    'relative',
    'inline-flex items-center justify-center gap-0.8',
    'group/button cursor-pointer font-semibold select-none disabled:cursor-default',
    'rounded-button',
  ],
  {
    variants: {
      theme: {
        primary: ['text-cWhite', 'bg-cPurple700'],
        focus: ['text-cWhite', 'bg-cPurple1100'],
        black: ['bg-black'],
        inverse: ['text-cPurple700', 'bg-cWhite'],
        blurred: ['text-cWhite', 'bg-cBlurred'],
        gray: 'bg-cGray900 text-white',
        green: 'bg-cGreen text-white',
        outline: [
          'text-cWhite text-1.6-600',
          'bg-transparent',
          'p-1.2 pr-2',
          'border-2 border-cGray700',
          'inline-flex gap-0.8',
        ],
      },
      stretched: {
        true: '!w-full text-center',
      },
      size: {
        md: 'py-2 px-2.8',
        sm: 'py-1.3 px-2',
        xs: 'p-1.2',
        fit: 'p-0',
        initial: '',
      },
      isIconAfter: {
        true: '!pl-2 flex-row-reverse',
        false: '!pr-2',
        default: '',
      },

      isLoading: {
        true: ['!text-opacity-0'],
        false: [],
      },
    },
    defaultVariants: {
      size: 'md',
      theme: 'primary',
    },
  }
);

export const cvaIcon = cva(
  [
    'ButtonStyles-cvaIcon',
    'inline-flex justify-center items-center',
    'text-0',
    'rounded-full',
  ],
  {
    variants: {
      theme: {},
      size: {},
      isLoading: {},
    },
    defaultVariants: {},
  }
);

export const cvaButtonInner = cva(
  ['ButtonStyles-cvaButtonInner', 'block overflow-hidden'],
  {
    variants: {
      size: {},
    },
  }
);

export const cvaButtonInnerRow = cva([
  'ButtonStyles-cvaButtonInnerRow',
  'relative',
  'block',
]);
