const v1Path = '/wp-json/v1';
const menusV1Path = '/wp-json/menus/v1';
const v2Path = '/wp-json/wp/v2';

export const API = {
  // AUTH
  getOAuthURL: `${v1Path}/auth/get-oauth-url`,
  login: `${v1Path}/auth/login`,
  logout: `${v1Path}/auth/logout`,
  refreshToken: `${v1Path}/auth/refresh`,

  // USER
  getMe: `${v1Path}/user/get-current-user`,
  getCurrentUserFields: `${v1Path}/user/get-current-user-fields/`,
  getMyPhotos: `${v1Path}/photo/get-current-user-photos`,
  updateProfile: `${v1Path}/user/update`,

  // PROFILE
  getProfile: `${v1Path}/profile/get`,
  getProfilePhotos: `${v1Path}/profile/get-photos`,
  getProfileDetailImage: `${v1Path}/profile/get-detail/`,
  subscribe: `${v1Path}/profile/subscribe`,
  unsubscribe: `${v1Path}/profile/unsubscribe`,
  getSubscriptions: `${v1Path}/profile/get-subscribes`,
  getSubscribers: `${v1Path}/profile/get-subscribers`,

  // AWARD
  getAwardByPlace: `${v1Path}/award/get-by-place`,
  getProfileAwardsFull: `${v1Path}/award-profile/get-full`,

  // FAVORITES
  getFavorites: `${v1Path}/favorite/get/`,
  getFavoriteCategories: `${v1Path}/favorite/get-categories`,
  addFavorite: `${v1Path}/favorite/add/`,
  removeFavorite: `${v1Path}/favorite/remove-by-photo-id`,
  getFavoritePhotoPage: `${v1Path}/favorite/get-photo-page/`,

  // VOTE
  getVote: `${v1Path}/vote/get`,
  getAvailableVoteCategories: `${v1Path}/vote/get-available-vote-categories/`,
  vote: `${v1Path}/vote/vote`,

  // POPULAR
  getPopular: `${v1Path}/popular/get`,
  getPopularByMonth: `${v1Path}/popular/get-by-month`,
  getPopularByWeek: `${v1Path}/popular/get-by-week`,
  getPopularPhotoPage: `${v1Path}/popular/get-photo-page`,

  // VIEW
  getDetailImage: `${v1Path}/view/get-detail`,

  // PHOTO
  getPhotoCategories: `${v1Path}/photo/get-categories`,
  uploadPhotos: `${v1Path}/photo/upload`,
  removePhotos: `${v1Path}/photo/remove`,

  // SHARE
  getShareLinks: `${v1Path}/share/get`,

  // COMMENTS
  addComment: `${v1Path}/comment/add`,
  removeComment: `${v1Path}/comment/remove`,

  // REPORT
  addReport: `${v1Path}/report/add`,

  // NOTIFY
  getNotifications: `${v1Path}/notify/get`,
  getNotViewedNotificationsCount: `${v1Path}/notify/get-not-viewed-count`,
  sendNotify: `${v1Path}/notify/send`,
  viewNotifications: `${v1Path}/notify/view`,

  // NOTIFY-REQUEST
  getIsRequested: `${v1Path}/notify-request/is-requested`,
  addNotifyRequest: `${v1Path}/notify-request/add`,

  // SEARCH
  search: `${v1Path}/search/search`,
  getSearchPhotoPage: `${v1Path}/search/get-photo-page/`,

  // CONTEST
  getContests: `${v2Path}/contest`,
  getContest: (id: number) => `${v2Path}/contest/${id}`,
  getContestPhotosCount: `${v1Path}/contest/get-contest-photos-count`,
  getIsUserInContest: `${v1Path}/contest/is-user-in-contest`,
  getContestUserPhotosCount: `${v1Path}/contest/get-contest-user-photos`,
  addContestPhotos: `${v1Path}/contest/add`,
  removeContestPhoto: `${v1Path}/contest/remove`,
  getContestPhotosWithUser: `${v1Path}/contest/get-contest-photos-with-user`,
  getContestWinners: `${v1Path}/contest/get-winners`,

  // PAGES
  getPageBySlug: `${v2Path}/pages`,
  getContestPage: `${v2Path}/pages/830`,
  getHomePage: `${v2Path}/pages/748`,
  getProfilePage: `${v2Path}/pages/759`,
  getProfileEditPage: `${v2Path}/pages/884`,
  getUploadPage: `${v2Path}/pages/798`,

  // OPTIONS
  getGlobalOptions: `${v1Path}/options/photos4me`,
  getHeaderOptions: `${v1Path}/options/photos4me-header`,
  getFooterOptions: `${v1Path}/options/photos4me-footer`,

  // MENUS
  getHeaderMenu: `${menusV1Path}/menus/80`,
  getFooterMenu: `${menusV1Path}/menus/81`,
  getOurProjectsMenu: `${menusV1Path}/menus/82`,
};
