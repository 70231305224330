import { FC, HTMLAttributes, createElement } from 'react';
import { cvaRoot } from './HeadingStyles';
import { VariantProps } from 'class-variance-authority';
import clsx from 'clsx';

interface Props
  extends HTMLAttributes<HTMLHeadingElement>,
    VariantProps<typeof cvaRoot> {
  level?: '1' | '2' | '3' | '4' | '5' | '6';
}

export const Heading: FC<Props> = ({
  className,
  level = '2',
  size,
  children,
  center,
  textColor,
  ...props
}) => {
  return createElement(
    `h${level}`,
    {
      className: clsx(cvaRoot({ size, center, textColor }), className),
      ...props,
    },
    children
  );
};
